<template>
    <ProLink
        class="insight-link-tile"
        :class="layout ? `insight-link-tile--${layout}` : ''"
        v-bind="$attrs"
        :aria-label="
            $t('insight.tile.label', {
                title: article.title,
                category: article.category.title,
                date: formatDateForLocale(article.display_date, currentLocaleISO),
            })
        "
        :to="{ name: 'insight-year-slug', params: { year: formatYear(article.display_date), slug: article.slug } }"
        :title="`${article.category.title} - ${article.title}`"
    >
        <div
            class="insight-link-tile__image"
            aria-hidden="true"
            :style="{
                'background-image': `url('${bucketImage(article.image)}')`,
                'background-color': article.cover_hex,
                color: `${article.cover_hex}40`,
            }"
        ></div>
        <div class="insight-link-tile__copy" aria-hidden="true">
            <div class="insight-link-tile__meta">
                <span :style="{ color: article.category.hex }">{{ article.category.title }}</span>
                <span class="default-display">{{ formatDateForLocale(article.display_date, currentLocaleISO) }}</span>
                <span class="compact-display">{{ formatDateForLocale(article.display_date, currentLocaleISO, "short") }}</span>
            </div>
            <h3 class="insight-link-tile__title text-heading text-heading--xs">{{ article.title }}</h3>
            <p v-if="read_time && $i18n.locale == 'en'" class="insight-link-tile__read-time">{{ $t("insight.read_time", { minutes: read_time }) }}</p>
        </div>
    </ProLink>
</template>

<script>
import { parseISO } from "date-fns";
import { formatDateForLocale, bucketImage } from "~/utils";

export default {
    props: {
        article: Object,
        layout: {
            type: String,
            default: "",
            validator(value) {
                return ["", "featured", "compact"].includes(value);
            },
        },
    },

    methods: {
        formatDateForLocale,
        bucketImage,

        formatYear(date) {
            return parseISO(date).getFullYear();
        },
    },
    computed: {
        currentLocaleISO() {
            return this.$i18n.locales.filter((locale) => locale.code == this.$i18n.locale)[0]?.iso;
        },

        read_time() {
            if (!this.article.word_count) return null;
            return Math.ceil(this.article.word_count / 200);
        },
    },
};
</script>
