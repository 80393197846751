var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ProLink',_vm._b({staticClass:"insight-link-tile",class:_vm.layout ? `insight-link-tile--${_vm.layout}` : '',attrs:{"aria-label":_vm.$t('insight.tile.label', {
            title: _vm.article.title,
            category: _vm.article.category.title,
            date: _vm.formatDateForLocale(_vm.article.display_date, _vm.currentLocaleISO),
        }),"to":{ name: 'insight-year-slug', params: { year: _vm.formatYear(_vm.article.display_date), slug: _vm.article.slug } },"title":`${_vm.article.category.title} - ${_vm.article.title}`}},'ProLink',_vm.$attrs,false),[_c('div',{staticClass:"insight-link-tile__image",style:({
            'background-image': `url('${_vm.bucketImage(_vm.article.image)}')`,
            'background-color': _vm.article.cover_hex,
            color: `${_vm.article.cover_hex}40`,
        }),attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('div',{staticClass:"insight-link-tile__copy",attrs:{"aria-hidden":"true"}},[_c('div',{staticClass:"insight-link-tile__meta"},[_c('span',{style:({ color: _vm.article.category.hex })},[_vm._v(_vm._s(_vm.article.category.title))]),_vm._v(" "),_c('span',{staticClass:"default-display"},[_vm._v(_vm._s(_vm.formatDateForLocale(_vm.article.display_date, _vm.currentLocaleISO)))]),_vm._v(" "),_c('span',{staticClass:"compact-display"},[_vm._v(_vm._s(_vm.formatDateForLocale(_vm.article.display_date, _vm.currentLocaleISO, "short")))])]),_vm._v(" "),_c('h3',{staticClass:"insight-link-tile__title text-heading text-heading--xs"},[_vm._v(_vm._s(_vm.article.title))]),_vm._v(" "),(_vm.read_time && _vm.$i18n.locale == 'en')?_c('p',{staticClass:"insight-link-tile__read-time"},[_vm._v(_vm._s(_vm.$t("insight.read_time", { minutes: _vm.read_time })))]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }