<template>
    <video disableremoteplayback x-webkit-airplay="deny" ref="video" :src="currentSource"></video>
</template>

<script>
import ResizeObserver from "resize-observer-polyfill";

export default {
    props: {
        sources: Array,
    },

    data: () => {
        return {
            currentSource: null,
            observer: null,
        };
    },

    methods: {
        setupResizeObserver() {
            this.observer = new ResizeObserver(() => {
                const matchingSource = this.sources.find((s) => window.matchMedia(s.media).matches) ?? this.sources.find((s) => s.media == undefined);
                this.currentSource = matchingSource.src;
            });

            this.observer.observe(this.$refs.video);
        },
    },

    created() {
        this.currentSource = this.sources[0].src; //Set a default src
    },

    mounted() {
        this.setupResizeObserver();
    },

    beforeDestroy() {
        this.observer.disconnect();
    },
};
</script>
