export const organization = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Procreate",
    url: "https://procreate.com/",
    image: "https://assets.procreate.art/img/procreate-icon-search-display.png",
    logo: "https://assets.procreate.art/img/procreate-icon-search-display.png",
    founder: [
        {
            "@context": "http://schema.org",
            "@type": "Person",
            name: "James Cuda",
            sameAs: ["https://twitter.com/cuda"],
        },
        {
            "@context": "http://schema.org",
            "@type": "Person",
            name: "Alanna Cuda",
        },
    ],
    sameAs: [
        "https://www.facebook.com/procreate",
        "https://twitter.com/Procreate",
        "https://www.instagram.com/Procreate/",
        "https://www.youtube.com/Procreate",
        "https://wikipedia.org/wiki/Procreate_(software)",
    ],
};

export const brand = {
    "@context": "http://schema.org",
    "@type": "Brand",
    name: "Procreate",
    url: "https://procreate.com",
    image: "https://assets.procreate.art/img/procreate-icon-search-display.png",
    logo: "https://assets.procreate.art/img/procreate-icon-search-display.png",
    sameAs: [
        "https://www.facebook.com/procreate",
        "https://twitter.com/Procreate",
        "https://www.instagram.com/Procreate/",
        "https://www.youtube.com/Procreate",
        "https://wikipedia.org/wiki/Procreate_(software)",
    ],
};

export const procreateProduct = {
    "@context": "http://schema.org",
    "@type": "Product",
    name: "Procreate",
    description:
        "Procreate is the most powerful and intuitive digital illustration app available for iPad. It's packed with features artists love, and it's iPad exclusive.",
    category: "Software",
    url: "https://procreate.com/procreate",
    logo: "https://assets.procreate.art/img/procreate-icon-search-display.png",
    image: "https://assets.procreate.art/img/procreate-icon-search-display.png",
    award: ["Apple Design Award 2022", "Apple Design Award 2013"],
    offers: {
        "@context": "http://schema.org",
        "@type": "Offer",
        price: "12.99",
        priceCurrency: "USD",
    },
    aggregateRating: {
        "@context": "http://schema.org",
        "@type": "AggregateRating",
        ratingValue: "4.5",
        ratingCount: "32700",
    },
    brand: brand,
    manufacturer: organization,
};

export const procreateDreamsProduct = {
    "@context": "http://schema.org",
    "@type": "Product",
    name: "Procreate Dreams",
    description:
        "Procreate Dreams is the all-new animation app with everything you need to create rich 2D animations, expressive videos & breathtaking stories.",
    category: "Software",
    url: "https://procreate.com/dreams",
    logo: "https://assets.procreate.art/img/dreams-search-display.png",
    image: "https://assets.procreate.art/img/dreams-search-display.png",
    offers: {
        "@context": "http://schema.org",
        "@type": "Offer",
        price: "19.99",
        priceCurrency: "USD",
    },
    aggregateRating: {
        "@context": "http://schema.org",
        "@type": "AggregateRating",
        ratingValue: "3.6",
        ratingCount: "661",
    },
    brand: brand,
    manufacturer: organization,
};

export const pocketProduct = {
    "@context": "http://schema.org",
    "@type": "Product",
    name: "Procreate Pocket",
    description:
        "Powerful enough for creative professionals. Simple enough for everyone. The award-winning Procreate Pocket is the most versatile art app ever designed for iPhone.",
    category: "Software",
    url: "https://procreate.com/pocket",
    logo: "https://assets.procreate.art/img/procreate-pocket-icon_white.png",
    image: "https://assets.procreate.art/img/procreate-pocket-icon_white.png",
    award: ["iPhone App of the year (2018)"],
    offers: {
        "@context": "http://schema.org",
        "@type": "Offer",
        price: "6.99",
        priceCurrency: "USD",
    },
    aggregateRating: {
        "@context": "http://schema.org",
        "@type": "AggregateRating",
        ratingValue: "3.9",
        ratingCount: "10400",
    },
    brand: brand,
    manufacturer: organization,
};

export const procreateSoftwareApplication = {
    "@context": "http://schema.org",
    "@type": "SoftwareApplication",
    name: "Procreate",
    description: procreateProduct.description,
    url: "https://apps.apple.com/us/app/procreate/id425073498",
    image: procreateProduct.image,
    applicationCategory: "DesignApplication", // Retrieved from https://developers.google.com/search/docs/appearance/structured-data/software-app#softwareapplication
    operatingSystem: "iPadOS",
    offers: procreateProduct.offers,
    aggregateRating: procreateProduct.aggregateRating,
    award: procreateProduct.award,
    author: organization,
    creator: organization,
    publisher: organization,
};

export const procreateDreamsSoftwareApplication = {
    "@context": "http://schema.org",
    "@type": "SoftwareApplication",
    name: "Procreate Dreams",
    description: procreateDreamsProduct.description,
    url: "https://apps.apple.com/us/app/procreate-dreams/id1595520602",
    image: procreateDreamsProduct.image,
    applicationCategory: "MultimediaApplication", // Retrieved from https://developers.google.com/search/docs/appearance/structured-data/software-app#softwareapplication
    operatingSystem: "iPadOS",
    offers: procreateDreamsProduct.offers,
    aggregateRating: procreateDreamsProduct.aggregateRating,
    author: organization,
    creator: organization,
    publisher: organization,
};

export const pocketSoftwareApplication = {
    "@context": "http://schema.org",
    "@type": "SoftwareApplication",
    name: "Procreate Pocket",
    description: pocketProduct.description,
    url: "https://apps.apple.com/us/app/procreate-pocket/id916366645",
    image: pocketProduct.image,
    applicationCategory: "DesignApplication", // Retrieved from https://developers.google.com/search/docs/appearance/structured-data/software-app#softwareapplication
    operatingSystem: "iOS",
    offers: pocketProduct.offers,
    aggregateRating: pocketProduct.aggregateRating,
    award: pocketProduct.award,
    author: organization,
    creator: organization,
    publisher: organization,
};

export function createJsonLdWrapper(data) {
    return {
        type: "application/ld+json",
        json: data,
    };
}
